<template>
    <b-container class="overflow-hidden">
        <b-modal id="modal-session-app" ok-only ok-variant="warning" ok-title="Login" modal-class="modal-session-app"
            centered title="Expired !" @ok="gotologin()">
            <b-card-text>Session Expired</b-card-text>
        </b-modal>
        <b-row class="d-flex justify-content-end my-1 mx-0" v-if="!progress">
            <div class="float-right">
                <div class="con-vs-tooltip">
                    <b-button
                        class="p-1 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer sm:inline-flex text-dark feather-icon select-none relative"
                        v-b-tooltip.hover title="show/hide Filter" @click="toggleFilterCard()">
                        <feather-icon icon="FilterIcon" size="14" />
                    </b-button>
                </div>
            </div>
        </b-row>
        <!--Filter-->
        <b-card ref="filterCard" class="hidden my-1">
            <div>
                <div class="d-md-flex align-items-center justify-content-between">
                    <div class="flex-auto">
                        <h5><feather-icon icon="DownloadIcon" size="20" /> Installs</h5>

                        <b-form-group>
                            <b-form-input type="number" placeholder="Min Installs" v-model="minInstalls" />
                        </b-form-group>
                        <b-form-group>
                            <b-form-input type="number" placeholder="Max Installs" v-model="maxInstalls" />
                            <small class="text-danger" ref="maxInstallsError"></small>
                        </b-form-group>
                    </div>
                    <div class="flex-auto">
                        <h5><feather-icon icon="CalendarIcon" size="20" /> Release</h5>

                        <b-form-group>
                            <b-form-datepicker v-model="minRelease" :max="maxDate" :date-format-options="{
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit',
                            }" placeholder="Min Date"></b-form-datepicker>
                        </b-form-group>

                        <b-form-group>
                            <b-form-datepicker v-model="maxRelease" :max="maxDate" :date-format-options="{
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit',
                            }" placeholder="Max Date"></b-form-datepicker>
                            <small class="text-danger" ref="maxReleaseError"></small>
                        </b-form-group>
                    </div>
                </div>

            </div>

            <div class="d-flex justify-content-center">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-success" class="mr-1 circle"
                    v-b-tooltip.hover title="Filter" @click="filterApps()">
                    Filter &nbsp; <feather-icon icon="FilterIcon" size="14" />
                </b-button>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-info" class="mr-1 circle"
                    type="submit" v-b-tooltip.hover title="Reset" @click="ResetFilters()">
                    Reset &nbsp; <feather-icon icon="RefreshCwIcon" size="14" />
                </b-button>
            </div>
        </b-card>


        <div>
            <div v-if="!progress">
                <!--Cards-->
                <div ref="analyticsCard" class="hidden p-0">
                    <b-row>
                        <b-col cols="4">
                            <b-card style="width: 100%">
                                <b-row>
                                    <b-col cols="9">
                                        {{ items.length }}
                                        <p class="fw-bold fs-5 text-gray-700 dark:text-gray-200">
                                            Listings
                                        </p>
                                    </b-col>
                                    <b-col cols="3">
                                        <div
                                            class="p-1 text-white bg-light-info rounded-circle d-flex align-items-center justify-content-center">
                                            <feather-icon icon="MenuIcon" size="25" />
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                        <b-col cols="4">
                            <b-card style="width: 100%">
                                <b-row class="p-0">
                                    <b-col cols="9">
                                        {{ averageAppInstall }}
                                        <p class="fw-bold fs-5 text-gray-700 dark:text-gray-200">
                                            Average App Install
                                        </p>
                                    </b-col>
                                    <b-col cols="3">
                                        <div
                                            class="p-1 text-white bg-light-warning rounded-circle d-flex align-items-center justify-content-center">
                                            <feather-icon icon="BarChart2Icon" size="25" />
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                        <b-col cols="4">
                            <b-card style="width: 100%">
                                <b-row class="p-0">
                                    <b-col cols="9">
                                        {{ totalInstall }}
                                        <p class="fw-bold fs-5 text-gray-700 dark:text-gray-200">
                                            Total Installs
                                        </p>
                                    </b-col>
                                    <b-col cols="3">
                                        <div
                                            class="p-1 text-white bg-light-success rounded-circle d-flex align-items-center justify-content-center">
                                            <feather-icon icon="DownloadIcon" size="25" />
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                </div>
                <b-card class="mt-1">
                    <b-row>
                        <b-col cols="6" class="mb-1" offset="6">
                            <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm"
                                label-for="filterInput" class="mb-0 d-flex align-items-center justify-content-end">
                                <b-input-group size="sm">
                                    <b-form-input id="filterInput" v-model="filter" type="search"
                                        placeholder="Type to Search" />
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">
                                            Clear
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12">
                            <b-table striped hover responsive :per-page="perPage" :current-page="currentPage"
                                :items="items" :fields="fields">
                                <template #cell(icon)="data">
                                    <img :src="data.value" alt="" width="20" />
                                </template>
                                <template #cell(developer)="data">
                                    <div id="developer">
                                        <router-link :to="{
                                            name: 'market-manager-accounts-search',
                                            query: {
                                                search: isNaN(data.item.developerId)
                                                    ? data.item.developer.devId
                                                    : data.item.developerId,
                                            },
                                        }" target="_blank">
                                            {{ data.item.developer.devId }}
                                        </router-link>
                                    </div>
                                </template>
                                <template #cell(released)="data">
                                    {{
                                        data.value
                                            ? moment(data.value, "YYYY/MM/DD").format("MMM DD, YYYY")
                                    : data.value
                                    }}
                                </template>
                                <template #cell(playstoreUrl)="data">
                                    <a :href="data.value" target="_blank" title="Visit on Store"
                                        class="btn btn-info btn-icon rounded-circle"><feather-icon icon="EyeIcon"
                                            size="20" /></a>
                                </template>
                            </b-table>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group class="mb-0">
                                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
                                    class="w-25" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                align="center" size="sm" class="my-0" />
                        </b-col>
                    </b-row>
                </b-card>
            </div>
            <div v-else>
                <b-card class="mt-5">
                    <div class="text-center my-4">
                        <b-spinner class="spinner" variant="primary" size="60" label="Text Centered" />
                    </div>
                </b-card>
            </div>
        </div>
    </b-container>
</template>

<script>
import vSelect from "vue-select";
import {
    BRow,
    BCol,
    BModal,
    BCard,
    BFormSelect,
    BTable,
    BInputGroup,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BFormFile,
    BImg,
    BForm,
    BInputGroupAppend,
    BCardText,
    BButtonGroup,
    VBTooltip,
    BPagination,
    BButton,
    VBHover,
    BContainer,
    BFormInvalidFeedback,
    BAspect,
    BSpinner,
    BFormDatepicker,
} from "bootstrap-vue";
import axios from "@axios";
import originAxios from "axios";
import appsAxios from "@/libs/appsAxios";
import router from "@/router";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import Editor from "@tinymce/tinymce-vue";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        BRow,
        BCol,
        BFormTextarea,
        BCard,
        BContainer,
        BFormInput,
        BFormGroup,
        BFormFile,
        BImg,
        BFormSelect,
        BInputGroupAppend,
        BForm,
        BTable,
        BCardText,
        BInputGroup,
        BModal,
        BButtonGroup,
        VBTooltip,
        BPagination,
        BButton,
        vSelect,
        BAspect,
        editor: Editor,
        BFormInvalidFeedback,
        BSpinner,
        BFormDatepicker,
    },
    directives: {
        "b-tooltip": VBTooltip,
        "b-hover": VBHover,
        Ripple,
    },
    data() {
        return {
            maxDate: new Date(),
            moment,
            search: null,
            applications: [],
            perPage: 10,
            pageOptions: [10, 25, 100],
            totalRows: 1,
            currentPage: 1,
            filter: null,
            items: [],
            progress: true,
            backUpApps: [],
            fields: [
                {
                    key: "index",
                    label: "",
                },
                {
                    key: "icon",
                    label: "",
                },
                {
                    key: "title",
                    label: "Title",
                },
                {
                    key: "scoreText",
                    label: "Score",
                },
                {
                    key: "maxInstalls",
                    label: "Installs",
                    sortable: true,
                },
                {
                    key: "developer",
                    label: "Account",
                },
                {
                    key: "released",
                    label: "Release date",
                    sortable: true,
                },
                {
                    key: "version",
                    label: "Version",
                },
                {
                    key: "playstoreUrl",
                    label: "",
                },
            ],
            minInstalls: null,
            maxInstalls: null,
            minRelease: null,
            maxRelease: null,
            favoritesAccounts: [],
            apps: [],
            totalInstall: 0,
            averageAppInstall: 0,
            token: null,
            server: 1,
            userId: null,
        };
    },
    mounted() {
        this.userId = JSON.parse(localStorage.getItem("userData")).id;
        this.allFavoriteAccounts();
        this.token = JSON.parse(localStorage.getItem("userAccess")).token;
        this.server = JSON.parse(localStorage.getItem("userAccess")).server;
    },
    watch: {
        minRelease(v) {
            return v ? v.replace("-", "/") : null;
        },
        maxRelease(v) {
            return v ? v.replace("-", "/") : null;
        },
    },
    methods: {
        showToast(variant, icon, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    text,
                    variant,
                },
            });
        },
        toggleAnalyticsCrad() {
            this.$refs.analyticsCard.classList.toggle("hidden");
        },
        allFavoriteAccounts() {
            axios
                .post("/all-accounts-in-favorite", { userId: this.userId })
                .then(({ data }) => {
                    data.collections.forEach((x) => {
                        this.favoritesAccounts.push(...x.favorite_accounts);
                    });
                    const promises = [];
                    for (const favAcc of this.favoritesAccounts) {
                        const search = isNaN(favAcc.devID) ? favAcc.name : favAcc.devID;
                        promises.push(this.accountSearch(search));
                    }
                    return Promise.all(promises);
                })
                .then(() => {
                    this.progress = false;
                    const apps = this.apps.flat();
                    this.items = this.addIndexToTable(apps);
                    this.backUpApps = this.items;
                    this.averageTotalApp(this.items);
                    this.totalRows = this.items.length;
                })
                .catch((error) => {
                    console.error();
                    this.progress = false;
                    this.items = this.applications = [];
                    this.totalRows = 0;
                });
        },
        filterApps() {
            const filteredApps = this.items.filter((app) => {
                if (this.minInstalls || this.maxInstalls) {
                    if (parseInt(this.minInstalls) < parseInt(this.maxInstalls)) {
                        if (this.minInstalls && app.maxInstalls < this.minInstalls) {
                            return false;
                        }
                        if (this.maxInstalls && app.maxInstalls > this.maxInstalls) {
                            return false;
                        }
                        this.$refs.maxInstallsError.textContent = "";
                    } else {
                        this.$refs.maxInstallsError.textContent =
                            "The max installs must be Greater than min installs ";
                    }
                }
                if (this.minRelease || this.maxRelease) {
                    if (new Date(this.minRelease) < new Date(this.maxRelease)) {
                        if (
                            this.minRelease &&
                            new Date(app.released) < new Date(this.minRelease)
                        ) {
                            return false;
                        }
                        if (
                            this.maxRelease &&
                            new Date(app.released) > new Date(this.maxRelease)
                        ) {
                            return false;
                        }
                        this.$refs.maxReleaseError.textContent = "";
                    } else {
                        this.$refs.maxReleaseError.textContent =
                            "The max release date must be Greater than min release date";
                    }
                }

                return true;
            });

            this.items = filteredApps.flat();
            this.averageTotalApp(this.items);
            this.totalRows = this.items.length;
        },
        averageTotalApp(apps) {
            this.averageAppInstall = 0;
            this.totalInstall = 0;
            apps.forEach((app) => {
                this.totalInstall += app.maxInstalls;
            });
            if (apps.length > 0) {
                this.averageAppInstall = parseInt(
                    this.totalInstall / this.items.length
                );
            }
        },
        accountSearch(search) {
            return appsAxios.get(`https://sc1.clicklab.app/api/developers/${search}?fullDetail=true`)
                .then((response) => {
                    const { status } = response.data;
                    if (status === "Unauthorized") {
                        removeLocalStorageItems();
                        this.$root.$emit("bv::show::modal", "modal-session-app");
                        return;
                    }
                    const results = response.data.apps;
                    return results;
                })
                .then((results) => {
                    this.apps.push(results);
                })
                .catch((error) => {
                    console.error();
                    this.progress = false;
                    this.items = this.applications = [];
                    this.totalRows = 0;
                });
        },
        addIndexToTable(table) {
            const t = table.map((item, index) => {
                item.index = index + 1;
                item.released = item.released
                    ? moment(item.released, "MMM DD, YYYY").format("YYYY/MM/DD")
                    : "";

                return item;
            });

            return t;
        },

        toggleFilterCard() {
            this.$refs.filterCard.classList.toggle("hidden");
        },
        ResetFilters() {
            this.minInstalls = null;
            this.maxInstalls = null;
            this.minRelease = null;
            this.maxRelease = null;
            this.items = this.backUpApps;
        },
        gotologin() {
            this.$router.push({ name: "auth-login" });
        },
        showToast(variant, icon, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    text,
                    variant,
                },
            });
        },
    },
    beforeRouteEnter(to, from, next) {
        const token = JSON.parse(localStorage.getItem("accessToken"));
        axios
            .post("/check-user-login", {
                token,
            })
            .then(({ data }) => {
                if (data.status === "success") {
                    next(true);
                } else {
                    removeLocalStorageItems();
                    next({ name: "auth-login" });
                }
            });
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
</style>
